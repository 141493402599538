// ** Redux Imports
import { combineReducers } from "redux";

// ** Reducers Imports
import auth from "./auth";
import navbar from "./navbar";
import layout from "./layout";
import app from "./app";
import manage_bots from "./manage-bots";
import manage_reports from "./manage-reports";
import offers from "./offers";
import ew_leads from "./ew-leads";

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  app,
  manage_bots,
  manage_reports,
  offers,
  ew_leads
});

export default rootReducer;
