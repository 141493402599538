// ** Initial State
const initialState = {
  languages: [],
  lang_options: [],
  is_loading: false,
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_LANGUAGES":
      const _languages = action.value;
      const _lang_options = _languages.map((lang) => {
        return {
          value: lang?.id,
          label: lang?.name,
        };
      });
      const _lang_code_options = _languages.map((lang) => {
        return {
          id: lang?.id,
          value: lang?.lang,
          label: lang?.name,
        };
      });
      return {
        ...state,
        languages: _languages,
        lang_options: _lang_options,
        lang_code_options: _lang_code_options
      };

    case "IS_LOADING":
      return { ...state, is_loading: action.value };

    default:
      return state;
  }
};

export default appReducer;
