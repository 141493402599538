import { data } from "jquery";
import { useEffect } from "react";
import types from "../../types";
// **  Initial State
const initialState = {
  offers: [],
  isLoading: false,
  offerUpdated: {},
};

const OfferReducer = (state = initialState, action) => {
  if (action.type === "GET_OFFERS") {
    return { ...state, offers: action.payload, isLoading: false };
  }
  if (action.type === "UPDATE_OFFERS") {
    return { ...state, offerUpdated: action.payload, isLoading: false };
  }
  return state;
};

export default OfferReducer;
