import types from "@store/types";
import _ from "lodash";

//** Initial State
const initialState = {
  conversation_history: {},
  conversation_filters: {
    col: "created_at",
    sortDirection: "desc",
    page: 1,
    perPage: 10,
  },
  selected_report: {
    botid: "",
    graphtabid: "",
  },
  dynamic_filters: null,
  dynamic_filters_options: null,
  dynamic_filters_form: null,
  customer_journey: [],
};

const manageReportReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.UPDATE_FILTERS_FORM_REPORT:
      return { ...state, dynamic_filters_form: action.value };

    case types.UPDATE_DYNAMIC_FILTERS_REPORT:
      return { ...state, dynamic_filters: action.value };

    case types.UPDATE_DYNAMIC_FILTERS_OPTIONS:
      return { ...state, dynamic_filters_options: action.value };

    case types.UPDATE_CONVERSATION_HISTORY:
      return { ...state, conversation_history: action.value };

    case types.UPDATE_CONVERSATION_FILTERS:
      return {
        ...state,
        conversation_filters: {
          ...state.conversation_filters,
          ...action.value,
        },
      };

    case types.UPDATE_SELECTED_REPORT:
      return {
        ...state,
        selected_report: {
          ...state.selected_report,
          ...action.value,
        },
      };

    case types.UPDATE_CUSTOMER_JOURNEY:
      return { ...state, customer_journey: action.value };

    default:
      return state;
  }
};

export default manageReportReducer;
