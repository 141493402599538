// **  Initial State
const initialState = {
  parent_leads: [],
  parent_city_outlets: [],
  isLoading: false,
};

const EWLeadsReducer = (state = initialState, action) => {
  if (action.type === "GET_PARENT_LEADS") {
    return { ...state, parent_leads: action.payload, isLoading: false };
  }

  if (action.type === "GET_PARENT_CITY_OUTLETS") {
    return { ...state, parent_city_outlets: action.payload, isLoading: false };
  }

  return state;
};

export default EWLeadsReducer;
