const types = {
  GET_BOTS: "GET_BOTS",
  SET_BOT_SELECTED: "SET_BOT_SELECTED",
  SET_BOT_THEMES: "SET_BOT_THEMES",
  SET_BOT_IM_MAPPING: "SET_BOT_IM_MAPPING",
  SET_BOT_TOKEN: "SET_BOT_TOKEN",
  SET_BOT_DEPLOYMENT_HANDLER: "SET_BOT_DEPLOYMENT_HANDLER",
  SET_BOT_DEPLOYMENT_VOICE: "SET_BOT_DEPLOYMENT_VOICE",
  SET_SANDBOX_CHAT_BOT: "SET_SANDBOX_CHAT_BOT",
  GET_BOT_BUILDER: "GET_BOT_BUILDER",
  UPDATE_FILTERS_FORM_REPORT: "UPDATE_FILTERS_FORM_REPORT",
  UPDATE_DYNAMIC_FILTERS_REPORT: "UPDATE_DYNAMIC_FILTERS_REPORT",
  UPDATE_DYNAMIC_FILTERS_OPTIONS: "UPDATE_DYNAMIC_FILTERS_OPTIONS",
  UPDATE_SELECTED_REPORT: "UPDATE_SELECTED_REPORT",
  UPDATE_CONVERSATION_HISTORY: "UPDATE_CONVERSATION_HISTORY",
  UPDATE_CONVERSATION_FILTERS: "UPDATE_CONVERSATION_FILTERS",
  UPDATE_CUSTOMER_JOURNEY: "UPDATE_CUSTOMER_JOURNEY",
};

export default types;
