import types from "@store/types";
import _ from "lodash";

// ** Initial State
const initialState = {
  bots: [],
  selected_bot: null,
  bot_themes: null,
  bot_im_mapping: null,
  bot_token: null,
  bot_deployment_handler: null,
  bot_deployment_voice: null,
  bot_sandbox_chat: [],
  bot_builder: null,
  // update_status_bot_builder: null,
};

const manageBotsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BOTS:
      return { ...state, bots: action.value };

    case types.SET_BOT_SELECTED:
      return { ...state, selected_bot: action.value };

    case types.SET_BOT_THEMES:
      return { ...state, bot_themes: action.value };

    case types.SET_BOT_IM_MAPPING:
      return { ...state, bot_im_mapping: action.value };

    case types.SET_BOT_TOKEN:
      return { ...state, bot_token: action.value };

    case types.SET_SANDBOX_CHAT_BOT:
      let _chat = [];
      if (action.value) {
        _chat = _.cloneDeep(state.bot_sandbox_chat);
        _chat.push(action.value);
      }

      return {
        ...state,
        bot_sandbox_chat: _chat || [],
      };

    case types.SET_BOT_DEPLOYMENT_HANDLER:
      return { ...state, bot_deployment_handler: action.value };

    case types.SET_BOT_DEPLOYMENT_VOICE:
      return { ...state, bot_deployment_voice: action.value };

    case types.GET_BOT_BUILDER:
      return { ...state, bot_builder: action.value };

    // case types.UPDATE_STATUS_BOT_BUILDER:
    //   return { ...state, update_status_bot_builder: action.value };

    default:
      return state;
  }
};

export default manageBotsReducer;
